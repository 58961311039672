import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default function Timeline() {

    return (
        <div className='shadow bg-gray-light drop-shadow-lg shadow-gray-light p-0 m-0 py-2 mt-3'>
            <h2 className='text-center text-orange text-[26px] pb-5 font-bold'>Timeline</h2>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2022-11-13"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                // icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Rent # 101116</h3>
                    <h4 className="vertical-timeline-element-subtitle">Jhon, Kia Picanto 54726</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2022-11-15"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                // icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Rent # 101117</h3>
                    <h4 className="vertical-timeline-element-subtitle">Christina, Carina 021546</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="2022-11-25"
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                // icon={<WorkIcon />}
                >
                    <h3 className="vertical-timeline-element-title">Rent # 101119</h3>
                    <h4 className="vertical-timeline-element-subtitle">Mike, BMW X6 128463</h4>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                // icon={<StarIcon />}
                />
            </VerticalTimeline>
        </div>
    )
}