import React from "react";
import TopMenu from "../Menu/TopMenu";
import Header from "../Menu/Header";

//import events from "./events";

export default function App() {
    return (
        <div className='flex flex-col min-h-screen'>
            <div className='w-100'>
                <TopMenu />
            </div>
            <div className='flex flex-row w-100 bg-gray-light m-0 pr-6 flex-1'>
                <div className='flex'>
                    <Header />
                </div>
                <div className='flex-grow pl-3 m-0 mt-2 flex-1'>
                    <div className="w-100 bg-[#ffff] border border-gray-light p-5 ">

                    </div>
                </div>
            </div>
        </div>
    );
}
