import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dropdown(props) {
    const [location, setLocation] = useState('--Choose lookup type')
    const [locationId, setLocationId] = useState(-1)

    const checkBoxType = () => {
        if (props.boxType === 'rent') {
            setLocation('Reservation #');
            setLocationId(1);
        } else if (props.boxType === 'lookup') {
            setLocation('Vehicule No');
            setLocationId(1);
        }
    }
    const dataList = (props.list).map((item) => {
        return <Menu.Item key={item.value}>
            {({ active }) => (
                <a onClick={(e) => { setLocation(e.target.text); setLocationId(e.target.target) }} target={item.value} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                    {item.name}
                </a>
            )}
        </Menu.Item>
    });

    useEffect(() => {
        checkBoxType();
    }, [])

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex w-full justify-center border border-gray-primary bg-gray-light p-3 px-4 text-sm font-medium text-black shadow-sm hover:bg-gray-50 focus:outline-none">
                    {location}
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-10 left-0 mt-2 w-56 bg-[#ffff] rounded-sm shadow-lg focus:outline-none">
                    <div className="py-1">
                        {dataList}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
