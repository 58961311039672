import Header from "../Menu/Header";
import TopMenu from "../Menu/TopMenu";
import axios from "axios";
import { useState, useEffect } from "react";
import { tdClass, trClass, tbodyClass } from '../Globals/Variables';
import { useNavigate } from 'react-router-dom';
import CustomerNew from "./CustomerNew";
import BackButton from "../Menu/BackButton";

export default function CustomerList() {
    const base_url = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate();

    // Get Customers
    const [customers, setCustomer] = useState([]);
    const getCustomer = () => {
        axios.post(base_url + '/getCustomers')
            .then((response) => {
                setCustomer(response.data)
            })
    }
    useEffect(() => {
        getCustomer();
    }, [])

    const customerData = customers.map((x, index) => {
        return <tr key={index}>
            <td className={tdClass}>{index + 1}</td>
            <td className={tdClass + ' cursor-pointer hover:underline text-blue'} onClick={() => navigate('/CustomerDetails', { state: x })}>{x.cusFirstName}</td>
            <td className={tdClass}>{x.cusLastName}</td>
            <td className={tdClass}>{x.cusPhoneNumber}</td>
            <td className={tdClass}>{x.cusCity}</td>
            <td>
                <button className="text-[#ffff] bg-red border p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    </svg>
                </button>
                <button className="text-[#ffff] bg-blue border p-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                    </svg>

                </button>
            </td>
        </tr>
    })

    return (
        <div className='flex flex-col min-h-screen'>
            <div className='w-100'>
                <TopMenu />
            </div>
            <div className='flex flex-row bg-gray-light m-0 pr-6 flex-1'>
                <div className='flex'>
                    <Header />
                </div>
                <div className='flex flex-col flex-grow pl-3 m-0 my-2'>
                    <div className="flex py-3">
                        <BackButton />
                        <div className="flex-row inline-block">
                            <CustomerNew />
                        </div>
                    </div>
                    <div className="w-100 bg-[#ffff] border border-gray-light flex-1 pl-4">
                        <table className="w-full">
                            <thead>
                                <tr className={trClass + ' font-bold'}>
                                    <td className={tdClass}>#</td>
                                    <td className={tdClass}>First Name</td>
                                    <td className={tdClass}>Last Name</td>
                                    <td className={tdClass}>Phone</td>
                                    <td className={tdClass}>Address</td>
                                    <td className={tdClass}></td>
                                </tr>
                            </thead>
                            <tbody className={tbodyClass}>
                                {customerData}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}