import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardMain from './DashoboardMain';
import CustomerNew from './Components/Customer/CustomerNew';
import CustomerList from './Components/Customer/CustomerList';
import CustomerDetails from './Components/Customer/CustomerDetails';
import Events from './Components/Calendar/Events';
import CarDetails from './Components/Cars/CarDetails';
import CarList from './Components/Cars/CarList';
import CarNew from './Components/Cars/CarNew';
import SetMain from './Components/Setting/SetMain';
import Authentication from './Authentication';
import Login from './Components/Login/UserLogin';
import { ReactSession } from "react-client-session";

function App() {
  ReactSession.setStoreType("localStorage");
  return (
    <Router>
      <Routes>
        <Route exact path="/Login" element={<Login />} />
        <Route element={<Authentication />}>
          <Route exact path="/" element={<DashboardMain />} />
          <Route exact path="/Customer" element={<CustomerList />} />
          <Route exact path="/NewCustomer" element={<CustomerNew />} />
          <Route exact path="/CustomerDetails" element={<CustomerDetails />} />
          <Route exact path="/Calendar" element={<Events />} />
          <Route exact path="/CarDetails" element={<CarDetails />} />
          <Route exact path="/CarList" element={<CarList />} />
          <Route exact path="/CarNew" element={<CarNew />} />
          <Route exact path="/Setting" element={<SetMain />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
