import Header from './Components/Menu/Header';
import Dashboard from './Components/Dashboard';
import TopMenu from './Components/Menu/TopMenu';

export default function DashboardMain() {
    return (
        <div className='flex flex-col'>
            <div className='w-100'>
                <TopMenu />
            </div>
            <div className='flex flex-row min-h-screen w-100 h-full bg-gray-light m-0 pr-6 '>
                <div className='flex'>
                    <Header />
                </div>
                <div className='flex-grow pl-3 m-0'>
                    <Dashboard />
                </div>
            </div>
        </div>
    )
}