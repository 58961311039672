import Cards from './Cards';
import Dropdown from './Dropdown';
import Timeline from './Timeline';

export default function Dashboard() {
    const lookupList =
        [
            {
                name: 'Vehicule No',
                value: '1'
            },

            {
                name: 'License No',
                value: '2'
            },

            {
                name: 'Customer Name',
                value: '3'
            },

            {
                name: 'Phone Number',
                value: '4'
            }
        ]
    const RentList =
        [
            {
                name: 'Rent No',
                value: '1'
            },

            {
                name: 'Agreement No',
                value: '2'
            }
        ]

    return (
        <div className="flex flex-col w-full justify-start">
            <div className="flex flex-row py-1 my-1 inline-block bg-white">
                <div className="flex flex-grow text-blue text-[30px] p-2">
                    Dashboard
                </div>
                <div className="flex py-3">
                    <div className="flex-row inline-block">
                        <button className='p-2 bg-blue border border-blue px-5 text-[#ffff] mx-1 hover:opacity-60 focus:border-blue'>Reservation</button>
                        <button className='p-2 bg-blue border border-blue px-5 text-[#ffff] mx-1 hover:opacity-60 focus:border-blue'>Agreement</button>
                    </div>
                </div>
            </div>

            <div className="flex lg:flex-row flex-col w-full w-100 m-0">
                <Cards name='Reservations' value='6' icon='icon-reservation' />
                <Cards name='On Rent' value='8' icon='icon-car' />
                <Cards name='Overdue' value='3' icon='icon-overdue' />
                <Cards name='Pending Payment' value='2' icon='icon-payment' />
                <Cards name='Maintenance' value='7' icon='icon-service' />
                <Cards name='Alert' value='15' icon='icon-alert' />
            </div>
            <div className='flex flex-col'>
                <div className='flex lg:flex-row flex-row m-0 z-10'>
                    <div className='lg:flex-col flex-row flex-1'>
                        <div className='bg-gray-light border border-gray-primary shadow drop-shadow-lg shadow-gry-primary p-2 px-4'>
                            <div className='flex-col flex-1 p-2 text-[20px] font-bold'>
                                Lookup
                            </div>
                            <div className='flex-col'>
                                <div className='flex flex-row'>
                                    <Dropdown list={lookupList} boxType='lookup' />
                                    <input type='text' className='border flex-1 border-2 border-gray-primary w-100 px-3 bg-gray-light focus:outline-none' />
                                    <button className='p-2 bg-blue border border-blue px-5 text-[#ffff] hover:opacity-60 focus:border-blue'>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lg:flex-row flex-row flex-1'>
                        <div className='bg-gray-light border border-gray-primary shadow drop-shadow-lg shadow-gry-primary p-2 px-4'>
                            <div className='flex-col flex-1 p-2 text-[20px] font-bold'>
                                Check
                            </div>
                            <div className='flex-col'>
                                <div className='flex flex-row'>
                                    <Dropdown list={RentList} boxType='rent' />
                                    <input type='text' className='flex-1 border border-2 border-gray-primary px-3 bg-gray-light focus:outline-none' />
                                    <button className='p-2 bg-blue border border-blue px-5 text-[#ffff] hover:opacity-60 focus:border-blue'>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-[100px]'>
                </div>
                <Timeline className='z-1' />
            </div>
        </div >
    );
}