import { useState, useEffect } from "react"
import axios, { isCancel, AxiosError } from 'axios'
import img1 from '../../Assets/img/img1.jpg'
import { Navigate, useNavigate } from "react-router-dom"
import { ReactSession } from 'react-client-session';
import { useCookies } from "react-cookie";

export default function UserLogin() {
    const base_url = process.env.REACT_APP_BASEURL
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies();
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)

    const [errUser, setErrUser] = useState(false)
    const [errPassword, setErrPassword] = useState(false)
    const [errMsg, setErrMsg] = useState('')

    const rememberMeHandle = (e) => {
        console.log()
    }

    const login = () => {
        let valid = true
        if (!user) {
            valid = false
            setErrUser(true)
        }

        if (!password) {
            valid = false
            setErrPassword(true)
        }

        if (valid) {
            axios.post(base_url + '/checkUser', {
                username: user,
                userpassword: password
            })
                .then((response) => {
                    if (response.status === 200) {
                        let result = response.data
                        if (result.status === true) {
                            if (result.data.log === true) {
                                console.log(result.data)
                                // setCookie("reid", result.data.id);
                                // setCookie("reuser", result.data.user);
                                ReactSession.set("reid", result.data.id);
                                ReactSession.set("reuser", result.data.user);
                                navigate('/')
                            }
                            else
                                setErrMsg('Please check your username/password.')
                        }
                        else {
                            setErrMsg(response.data.err.code)
                        }
                    }
                })
                .catch((error) => {
                    setErrMsg(error.code)
                })
        }

    }

    return (
        <div className="flex fixed w-full h-full">
            <div className="flex flex-col fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-4 rounded shadow drop-shadow bg-[#ffff] justify-center min-w-[30%] z-50">
                <div className="justify-center text-center">
                    <p className="font-bold italic text-xl text-orange">Login to Get Started</p>
                </div>
                <label className="font-bold m-2">Username</label>
                <input className={"border border-gray p-1 outline-none focus:border-orange" + (errUser ? ' border-red' : '')} onChange={(e) => { setErrUser(false); setUser(e.target.value) }} value={user} />
                <label className="font-bold m-2">Password</label>
                <input type={'password'} className={"border border-gray p-1 outline-none focus:border-orange" + (errPassword ? ' border-red' : '')} onChange={(e) => { setErrPassword(false); setPassword(e.target.value) }} value={password} />
                <div className="flex">
                    <input type={'checkbox'} id='rememberme' className="" onChange={(e) => setRememberMe(e.target.checked)} checked={rememberMe} />
                    <label htmlFor="rememberme" className="p-2">Remember me</label>
                </div>
                <div className="flex justify-center text-red italic w-full">
                    {errMsg}
                </div>
                <div className="flex justify-end">
                    <button className="bg-blue hover:opacity-60 p-2 px-5 w-20 text-[#ffff]" onClick={login}>Login</button>
                </div>
            </div>
            <div className="fixed z-40 opacity:60 object-fill">
                <img src={img1} className='object-cover h-screen w-screen opacity-60' />
            </div>
        </div>
    )
}