import TopMenu from '../Menu/TopMenu';
import Header from '../Menu/Header';
import General from './General';
import { useGlobalState } from '../Globals/GlobalStates';

export default function SetMain() {
    const [showGeneral, setShowGeneral] = useGlobalState('showGeneralTab')

    const showGeneralTab = () => {
        if (showGeneral === 'hidden')
            setShowGeneral('')
        else
            setShowGeneral('hidden')
    }

    return (
        <div className='flex flex-col'>
            <div className='w-100'>
                <TopMenu />
            </div>
            <div className='flex flex-row min-h-screen w-100 h-full bg-gray-light m-0 pr-6 '>
                <div className='flex'>
                    <Header />
                </div>
                <div className='flex-grow pl-3 m-0 h-full'>
                    <div className='flex flex-col min-h-full m-1 border border-gray shadow'>
                        <div className='flex flex-row min-h-full justify-start'>
                            <div className='p-3 px-5 hover:bg-blue hover:text-[#ffff]' onClick={() => showGeneralTab()}>General</div>
                            <div className='p-3 px-5 hover:bg-blue hover:text-[#ffff]'>Security</div>
                            <div className='p-3 px-5 hover:bg-blue hover:text-[#ffff]'>Users</div>
                            <div className='p-3 px-5 hover:bg-blue hover:text-[#ffff]'>Email</div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <General />
                    </div>
                </div>
            </div>
        </div>
    )
}