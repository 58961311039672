import { AiFillCar, AiFillCreditCard, AiOutlineCheck, AiFillDollarCircle } from 'react-icons/ai';
import { MdOutlineMiscellaneousServices } from 'react-icons/md';
import { TbAlertCircle } from 'react-icons/tb';
export default function Cards(props) {

    const getPath = () => {
        switch (props.icon) {
            case 'icon-reservation':
                return <AiOutlineCheck className='text-[30px] text-blue' />;
            case 'icon-overdue':
                return <AiFillCreditCard className='text-[30px] text-blue' />;
            case 'icon-car':
                return <AiFillCar className='text-[30px] text-blue' />;
            case 'icon-payment':
                return <AiFillDollarCircle className='text-[30px] text-blue' />;
            case 'icon-service':
                return <MdOutlineMiscellaneousServices className='text-[30px] text-blue' />;
            case 'icon-alert':
                return <TbAlertCircle className='text-[30px] text-blue' />;
            default:
                return <path />;
        }
    }

    return (
        <div className="flex flex-row flex-1 border border-gray-light rounded-[5px] lg:max-w-[300px] w-full lg:mx-1 my-3 p-2 shadow drop-shdow shadow-gray bg-white">
            <div className="flex p-2 flex-start vertical-center p-2 m-auto border rounded-full border-gray-primary bg-gray-primary">
                {getPath()}
            </div>
            <div className="flex-1 flex-col p-2 pl-8">
                <div className="flex-1 font-bold text-[25px]">
                    {props.value}
                </div>
                <div className="flex-1 text-gray">
                    {props.name}
                </div>
            </div>
        </div>
    )
}
