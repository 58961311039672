import { useEffect, useState } from 'react'

export default function TopMenu() {
    const [user, setUser] = useState('')

    useEffect(() => {

    })
    return (
        <div className=" w-100 h-[50px] bg-blue">
            <div className="float-left p-2 ml-2 text-[20px] text-[#ffff] font-sans font-bold italic">
                <label>Rent Management System</label>
                <input type={'text'} placeholder="Search anything" className="border rounded-2 ml-5 w-[300px] text-[14px] p-1 font-normal" />
            </div>
            <div className="w-100 float-right flex flex-row">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 m-2 text-[#ffff]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 m-2 mr-10 text-[#ffff]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>

            </div>
        </div>
    )
}