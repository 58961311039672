import { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useGlobalState, setGlobalState } from '../Globals/GlobalStates';

export default function CarNoteNew(props) {
    const { state } = useLocation();
    const [showModal, setShowModal] = useState(false);
    const base_url = process.env.REACT_APP_BASEURL;
    const [refreshCarNote, setRefreshCarNote] = useGlobalState("updateCarNote");

    const modalState = () => {
        console.log(showModal)
        if (showModal == false)
            setShowModal(true)
        else
            setShowModal(false)
    }

    //Car Details
    const [noteId, setNoteId] = useState(0);
    const [carId, setCarId] = useState(state.id);
    const [noteDate, setNoteDate] = useState('');
    const [noteText, setNoteText] = useState('');

    // Clear State
    const clearState = () => {
        setNoteId('')
        //setCarId('')
        setNoteDate('')
        setNoteText('')
        setShowModal(false)
    }
    //Fields Error of Customer details
    const [errNoteId, setErrNoteId] = useState();
    const [errCarId, setErrCarId] = useState();
    const [errNoteDate, setErrNoteDate] = useState();
    const [errNotetext, setErrNoteText] = useState();


    //Add Car Model
    const addNote = () => {
        let valid = true;
        if (noteDate === '') {
            setErrNoteDate('border borde-orange')
            valid = false;
        }
        if (noteText === '') {
            setErrNoteText('border borde-orange')
            valid = false
        }
        console.log(errNoteDate, errNotetext)

        if (valid) {
            axios.post(base_url + "/addCarNote",
                {
                    carId,
                    noteDate,
                    noteText
                })
                .then((response) => {
                    if (response.status == 200) {
                        console.log('Car Note added successfully')
                        setRefreshCarNote(true)
                        clearState();
                    }
                    else
                        console.log(response);
                });
        }
    }

    return (
        <>
            <div className='flex bg-orange text-[#ffff] m-2 rounded-[5px]' onClick={() => { modalState() }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </div>
            {
                showModal ? (
                    <div className="top-[50px] fixed w-full">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto  mx-auto max-w-3xl bg-[#fff] border-solid border-slate-200 rounded-t z-50">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5  rounded-t">
                                        <h3 className="text-2xl font-semibold">
                                            New Note for {props.data.plate}
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative flex-auto">
                                        <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-4 ml-2">
                                            <div className="flex flex-grow flex-col lg:flex-row place-items-center items-start px-3">
                                                <div className="flex-1 px-5">
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Date <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errNoteDate}
                                                                value={noteDate}
                                                                onChange={(e) => { setErrNoteDate(''); setNoteDate(e.target.value) }}
                                                                type='date'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Note <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errNotetext}
                                                                value={noteText}
                                                                onChange={(e) => { setErrNoteText(''); setNoteText(e.target.value) }}
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    < div className="flex items-center justify-end" >
                                        <button
                                            className="text-orange font-bold uppercase px-3 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { setShowModal(false); }}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="text-blue active:bg-emerald-600 font-bold uppercase text-sm px-3 py-3 hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => addNote()}
                                        >
                                            Save Changes
                                        </button>
                                    </div >
                                </div >
                            </div >
                            <div className="fixed bg-gray opacity-25 inset-0" onClick={() => setShowModal(false)}></div>
                        </div >
                    </div>
                ) : null
            }
        </>
    );
}