import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
    updateCarNote: false,
    updateCarDetail: false,
    showReservationEmpty: 'hidden',
    updateCustomerNote: false,
    showGeneralTab: 'hidden',
    settingList: [],
});

export { useGlobalState, setGlobalState };