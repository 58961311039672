import { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useGlobalState, setGlobalState } from '../Globals/GlobalStates';
import moment from "moment";

export default function CarReservation(props) {
    const ref = useRef();
    const { state } = useLocation();
    const [showModal, setShowModal] = useState(false);
    const base_url = process.env.REACT_APP_BASEURL;
    const [refreshCarDetail, setRefreshCarDetail] = useGlobalState("updateCarDetail");
    //Car Details
    const [carId, setCarId] = useState(state.id ? state.id : 0);
    const [price, setPrice] = useState('');
    const [customerId, setCustomerId] = useState(0);
    const [locationId, setLocationId] = useState(state.locId ? state.locId : 0);
    const [comment, setComment] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [phone, setPhone] = useState(state.cusPhoneNumber ? state.cusPhoneNumber : '');
    const [customerName, setCustomerName] = useState(state.cusFirstName ? state.cusFirstName + ' ' + state.cusLastName : '');
    const [duration, setDuration] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [settingList, setSettingList] = useGlobalState('settingList');
    const [currency, setCurrency] = useState('');

    // Clear State
    const clearState = () => {
        setCarId()
        setPrice()
        setCustomerId()
        setLocationId()
        setComment()
        setStartDate()
        setEndDate()
        setPhone()
        setCustomerName()
        setDuration()
        setShowModal(false)
    }
    //Fields Error of Customer details
    const [errCarId, setErrCarId] = useState('');
    const [errPrice, setErrPrice] = useState('');
    const [errCustomerId, setErrCustomerId] = useState('');
    const [errLocationId, setErrLocationId] = useState('');
    const [errComment, setErrComment] = useState('');
    const [errStartDate, setErrStartDate] = useState('');
    const [errEndDate, setErrEndDate] = useState('');

    const [carList, setCarList] = useState();
    const getCars = () => {
        axios.post(base_url + "/getCars")
            .then((response) => {
                if (response.status == 200) {
                    setCarList(response.data)
                }
                else
                    console.log(response);
            });
    }

    const [locationList, setLocationList] = useState();
    const getLocation = () => {
        axios.post(base_url + "/getCarLocation")
            .then((response) => {
                if (response.status == 200) {
                    setLocationList(response.data)
                    //console.log(response.data)
                }
                else
                    console.log(response);
            });
    }

    const getDuration = (e, t) => {
        if (t === 's') {
            const sDate = moment(e)
            const eDate = moment(endDate)
            const diff = moment.duration(eDate.diff(sDate))
            if (diff > 0)
                setDuration(diff.days())
        }
        else if (t === 'e') {
            const sDate = moment(startDate)
            const eDate = moment(e)
            const diff = moment.duration(eDate.diff(sDate))
            if (diff > 0)
                setDuration(diff.days())
        }
    }

    const getTotal = () => {
        setTotalPrice(duration * price)
    }

    const checkPhone = (e) => {
        axios.post(base_url + "/checkPhone", { phone })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        setCustomerId(response.data[0].cusId)
                        setCustomerName(response.data[0].cusFirstName + ' ' + response.data[0].cusLastName)
                    }
                }
                else
                    console.log(response);
            });
    }

    const addReservation = () => {
        let valid = true
        if (carId == 0) {
            setErrCarId(' border-orange')
            valid = false
        }
        if (price == 0) {
            setErrPrice(' border-orange')
            valid = false
        }
        if (customerId == 0) {
            setErrCustomerId(' border-orange')
            valid = false
        }
        if (locationId == 0) {
            setErrLocationId(' border-orange')
            valid = false
        }
        if (startDate == '') {
            setErrStartDate(' border-orange')
            valid = false
        }
        if (endDate == '') {
            setErrEndDate(' border-orange')
            valid = false
        }
        let dateNow = new Date()
        dateNow = moment(dateNow).format("YYYY-MM-DD")
        if (valid) {
            axios.post(base_url + '/addReservation',
                {
                    carId,
                    price,
                    customerId,
                    locationId,
                    comment,
                    startDate,
                    endDate,
                    dateNow,
                    duration
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.status === true) {
                            clearState()
                            setRefreshCarDetail(true)
                            setShowModal(false)
                        }
                    }
                    else
                        console.log(response)
                })
        }
    }

    const getSetting = () => {
        axios.post(base_url + '/getSetting')
            .then((response) => {
                if (response.status === 200 && response.data.status === 'true')
                    setSettingList(response.data.result)
            })
    }

    useEffect(() => {
        getSetting();
        getLocation();
        getCars();
    }, [])

    useEffect(() => {
        getTotal()
        if (settingList.length > 0)
            setCurrency(settingList.filter(x => x.setCode = 100)[0].setValue)
    }, [duration, price])

    return (
        <>
            <button
                className='p-2 bg-blue border border-b-[#fff] px-5 text-[#ffff] mx-1 hover:opacity-60 focus:border-blue ease-linear transition-all duration-150'
                type="button"
                onClick={() => setShowModal(true)}
            >
                New Reservation
            </button>
            {
                showModal ? (
                    <div className="top-[50px] fixed w-full z-10 ">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 mx-auto max-w-3xl bg-[#fff] border-solid border-slate-200 rounded-t shadow drop-shadow-xl z-50">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5  rounded-t">
                                        <h3 className="text-2xl font-semibold">
                                            New Reservation
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative flex-auto">
                                        <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-4 ml-2">
                                            <div className="flex flex-wrap flex-grow flex-col lg:flex-row place-items-center items-start px-3">
                                                <div className="flex-1 px-5 mb-5 ">
                                                    <h3 className="font-bold">Car Info</h3>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Car <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto ml-3">
                                                            <select
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + errCarId}
                                                                value={carId}
                                                                onChange={(e) => { setCarId(e.target.value) }}
                                                            >
                                                                <option value={-1}></option>
                                                                {
                                                                    carList
                                                                        ? carList.map((x, index) => {
                                                                            return <option key={index} value={x.id}>{x.plate} - {x.modelName} - {x.makeName}</option>
                                                                        })
                                                                        : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Price/Day <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-40 px-3 focus:outline-none focus:border-blue border ' + errPrice}
                                                                value={price}
                                                                onChange={(e) => { setPrice(e.target.value); }}
                                                                type='number'
                                                            />
                                                        </div>
                                                    </div>
                                                    <label className="italic text-sm px-2 font-bold">Total for {duration} day(s): {totalPrice} {currency}</label>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Location <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <select
                                                                className={'py-1 pl-7 border-1 border-gray-primary min-w-full px-3 focus:outline-none focus:border-blue border ' + errLocationId}
                                                                value={locationId}
                                                                onChange={(e) => { setLocationId(e.target.value) }}
                                                            >
                                                                <option value={-1}></option>
                                                                {
                                                                    locationList
                                                                        ? locationList.map((x, index) => {
                                                                            return <option key={index} value={x.locId}>{x.locName}</option>
                                                                        })
                                                                        : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 px-5">
                                                    <h3 className="font-bold">Customer Info</h3>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Phone <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errCustomerId}
                                                                value={phone}
                                                                onChange={(e) => setPhone(e.target.value)}
                                                                onBlur={(e) => { checkPhone(e.target.value) }}
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Name <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + null}
                                                                value={customerName}
                                                                readOnly
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 px-5">
                                                    <h3 className="font-bold">Period</h3>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Start Day <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errStartDate}
                                                                value={startDate}
                                                                onChange={(e) => { setStartDate(e.target.value); getDuration(e.target.value, 's') }}
                                                                type='date'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1">End Day <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errEndDate}
                                                                value={endDate}
                                                                onChange={(e) => { setEndDate(e.target.value); getDuration(e.target.value, 'e') }}
                                                                type='date'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Duration <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3 w-1/2">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + null}
                                                                value={duration}
                                                                onChange={(e) => { setDuration(e.target.value); }}
                                                                type='text'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 px-5">
                                                    <h3 className="font-bold">Additional Info</h3>
                                                    <div className="flex flex-col py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Comment <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <textarea
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + null}
                                                                value={comment}
                                                                onChange={(e) => { setComment(e.target.value) }}
                                                                type='text'
                                                                rows={4}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    < div className="flex items-center justify-end" >
                                        <button
                                            className="text-orange font-bold uppercase px-3 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { clearState(); setShowModal(false); }}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="text-blue active:bg-emerald-600 font-bold uppercase text-sm px-3 py-3 hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { addReservation() }}
                                        >
                                            Save Changes
                                        </button>
                                    </div >
                                </div >
                            </div >
                            <div className="fixed bg-gray opacity-25 inset-0" onClick={() => setShowModal(false)}></div>
                        </div >
                    </div>
                ) : null
            }
        </>
    );
}