import { useState, useEffect, useImperativeHandle, forwardRef, useRef, useMemo } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useGlobalState, setGlobalState } from './Globals/GlobalStates';
import Dropzone from "react-dropzone";
import ReactUploadFile from "react-upload-file"
import FileUploading from 'react-files-uploading';

import { CircularProgressbar } from "react-circular-progressbar";

export default function UploadAttachment(props) {
    const { state } = useLocation();
    const [showModal, setShowModal] = useState(false);
    const base_url = process.env.REACT_APP_BASEURL;
    const [refreshCarNote, setRefreshCarNote] = useGlobalState("updateCarNote");
    const [files, setFiles] = useState([]);
    const [filesList, setFilesList] = useState([]);
    const [percents, setPErcent] = useState(0);
    const [fileUploaded, setFileUploaded] = useState()
    const [fileId, setFileId] = useState(1)

    const modalState = () => {
        if (showModal == false)
            setShowModal(true)
        else
            setShowModal(false)
    }

    const onchangeHandle = async (fileList) => {
        // fileList.
        await console.log(fileList)
        await filesList.push({ id: fileId, file: fileList, percent: 0 })
        await uploadFile(fileList, fileId)
        await setFileId(prev => prev + 1)
    };

    const uploadFile = (file, id) => {
        let data = new FormData();
        file.map(f => {
            console.log(f)
            data.append("file", f);
        })
        //data.append("file", file[0]);


        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let per = Math.floor((loaded * 100) / total);

                if (per <= 100) {
                    const found = filesList.map((obj) => {
                        if (obj.id === id) {
                            return { ...obj, percent: per }
                        }
                        return { ...obj }
                    })
                    setFilesList(found)
                }
            },
        };

        axios
            .post(base_url + "/upload", data, options)
            .then((res) => {
                console.log(res)
            });
    };

    //
    const addNote = () => {
        // let valid = true;
        // if (noteDate === '') {
        //     setErrNoteDate('border borde-orange')
        //     valid = false;
        // }
        // if (noteText === '') {
        //     setErrNoteText('border borde-orange')
        //     valid = false
        // }

        // if (valid) {
        //     axios.post(base_url + "/addCarNote",
        //         {
        //             carId,
        //             noteDate,
        //             noteText
        //         })
        //         .then((response) => {
        //             if (response.status == 200) {
        //                 console.log('Car Note added successfully')
        //                 setRefreshCarNote(true)
        //                 clearState();
        //             }
        //             else
        //                 console.log(response);
        //         });
        // }
    }

    return (
        <>
            <div className='flex bg-orange text-[#ffff] m-2 rounded-[5px]' onClick={() => { modalState() }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </div>
            {
                showModal ? (
                    <div className="top-[50px] fixed w-full">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto  mx-auto max-w-3xl bg-[#fff] border-solid border-slate-200 rounded-t">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5  rounded-t">
                                        <h3 className="text-2xl font-semibold">
                                            Upload Files
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative flex-auto">
                                        <div className="flex-grow bg-[#fff]] m-0 mt-4 mb-4 ml-2">
                                            <div className="flexflex-col lg:flex-row place-items-center justify-center items-start">
                                                <FileUploading multiple value={files} maxNumber={10} onChange={onchangeHandle} >
                                                    {({
                                                        fileList,
                                                        errors,
                                                        isDragging,
                                                        onFileUpload,
                                                        onFileRemoveAll,
                                                        onFileUpdate,
                                                        onFileRemove,
                                                        dragProps,
                                                    }) => {
                                                        return (
                                                            <div className="upload__file-wrapper flex flex-col border border-0 mx-2">
                                                                {errors && errors.maxNumber && (
                                                                    <span>Number of selected files exceed maxNumber</span>
                                                                )}

                                                                <button
                                                                    id="btn-upload"
                                                                    type="button"
                                                                    style={isDragging ? { color: 'red' } : undefined}
                                                                    onClick={onFileUpload}
                                                                    {...dragProps}
                                                                    className='min-h-[100px] shadow italic text-sm text-orange'
                                                                >
                                                                    Click or Drop your files here here
                                                                </button>

                                                                {/* <button id="btn-remove" type="button" onClick={onFileRemoveAll}>
                                                                    Remove all files
                                                                </button> */}

                                                                <div id="list-files">
                                                                    {filesList.map((file, index) => (
                                                                        <div key={`file-${index}`} className="flex items-center justify-start">
                                                                            <CircularProgressbar className="w-20" value={0} text={`${file.percent}%`} />
                                                                            <p className="text-sm">{file.file[0].name}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </FileUploading>
                                            </div>
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    < div className="flex items-center justify-end" >
                                        <button
                                            className="text-orange font-bold uppercase px-3 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { setShowModal(false); }}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="text-blue active:bg-emerald-600 font-bold uppercase text-sm px-3 py-3 hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => addNote()}
                                        >
                                            Save Changes
                                        </button>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div>
                ) : null
            }
        </>
    );
}