import { useState, useEffect } from "react";
import axios from "axios";

export default function CarNew() {
    const [showModal, setShowModal] = useState(false);
    const base_url = process.env.REACT_APP_BASEURL;

    //Car Details
    const [modelId, setModelId] = useState();
    const [makeId, setMakeId] = useState();
    const [year, setYear] = useState();
    const [categoryId, setCategoryId] = useState();
    const [availabilityId, setAvailabilityId] = useState();
    const [locationId, setLocationId] = useState();
    const [mileage, setMileage] = useState();
    const [plate, setPlate] = useState();

    // Clear State
    const clearState = () => {
        setModelId(''); setErrModelId('')
        setMakeId(''); setErrMakeId('')
        setYear(''); setErrYear('')
        setCategoryId(''); setErrCategoryId('')
        setAvailabilityId(''); setErrAvailabilityId('')
        setLocationId(''); setErrLocationId('')
        setMileage(''); setErrMileage('')
        setPlate(''); setErrPlate('')
        setShowModal(false)
    }

    //Fields Error of Customer details
    const [errModelId, setErrModelId] = useState();
    const [errMakeId, setErrMakeId] = useState();
    const [errYear, setErrYear] = useState();
    const [errCategoryId, setErrCategoryId] = useState();
    const [errAvailabilityId, setErrAvailabilityId] = useState();
    const [errLocationId, setErrLocationId] = useState();
    const [errMileage, setErrMileage] = useState();
    const [errPlate, setErrPlate] = useState();

    const [modelList, setModelList] = useState([]);
    const getModelList = () => {
        axios.post(base_url + "/getCarModel")
            .then((response) => {
                if (response.data.length > 0) {
                    setModelList(response.data)
                } else console.log('No data to show');
            });
    }

    const [makeList, setMakeList] = useState([]);
    const getMakeList = () => {
        axios.post(base_url + "/getCarMake")
            .then((response) => {
                if (response.data.length > 0) {
                    setMakeList(response.data)
                } else console.log('No data to show');
            });
    }

    const [categoryList, setCategoryList] = useState([]);
    const getCategoryList = () => {
        axios.post(base_url + "/getCarCategory")
            .then((response) => {
                if (response.data.length > 0) {
                    setCategoryList(response.data)
                } else console.log('No data to show');
            });
    }

    const [locationList, setLocationList] = useState([]);
    const getLocationList = () => {
        axios.post(base_url + "/getCarLocation")
            .then((response) => {
                if (response.data.length > 0) {
                    setLocationList(response.data)
                } else console.log('No data to show');
            });
    }

    useEffect(() => {
        getModelList();
        getMakeList();
        getCategoryList();
        getLocationList();
    }, [])


    //Add Car
    const addCar = () => {
        let valid = true;
        if (modelId == '') {
            setErrModelId('border-orange')
            valid = false;
        }
        if (makeId == '') {
            setErrMakeId('border-orange')
            valid = false
        }

        if (year == '') {
            setErrYear('border-orange')
            valid = false
        }
        if (categoryId == '') {
            setErrCategoryId('border-orange')
            valid = false
        }
        if (availabilityId == '') {
            setErrAvailabilityId('border-orange')
            valid = false
        }
        if (locationId == '') {
            setErrLocationId('border-orange')
            valid = false
        }
        if (mileage == 0) {
            setErrMileage('border-orange')
            valid = false
        }
        if (plate == '') {
            setErrPlate('border-orange')
            valid = false
        }

        if (valid) {
            axios.post(base_url + "/addCar",
                {
                    modelId,
                    makeId,
                    year,
                    categoryId,
                    availabilityId,
                    locationId,
                    mileage,
                    plate,
                })
                .then((response) => {
                    if (response.status == 200) {
                        console.log('Car added successfully')
                        clearState();
                    }
                    else
                        console.log(response);
                });
        }
    }

    return (
        <>
            <button
                className='p-2 bg-blue border border-b-[#fff] px-5 text-[#ffff] mx-1 hover:opacity-60 focus:border-blue ease-linear transition-all duration-150'
                type="button"
                onClick={() => { setAvailabilityId(1); setShowModal(true) }}
            >
                New Car
            </button>
            {
                showModal ? (
                    <div className="fixed w-full z-50">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 mx-auto max-w-3xl bg-[#fff] border-solid border-slate-200 rounded-t z-50">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 rounded-t">
                                        <h3 className="text-2xl font-semibold">
                                            New Car
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>

                                    {/*body*/}
                                    <div className="relative flex flex-row">
                                        <div className="flex-grow px-5 bg-[#ffff]  m-0 mt-4 mb-4 ml-2">
                                            <div className="flex flex-grow flex-col lg:flex-row place-items-center items-start px-3">
                                                <div className="flex-1 px-5">
                                                    <div className="flex flex-row py-2">
                                                        <div className="p-1 w-[110px]">
                                                            <label className="p-1 text-md">Plate <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errPlate}
                                                                value={plate}
                                                                type='text' maxLength={200}
                                                                onChange={(e) => { setErrPlate(''); setPlate(e.target.value ? e.target.value : null) }} />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-2">
                                                        <div className="p-1 w-[100px]">
                                                            <label className="p-1 text-md">Model <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <select
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errModelId}
                                                                value={modelId}
                                                                onChange={(e) => { setErrModelId(''); setModelId(e.target.value) }}>
                                                                <option value={-1}></option>
                                                                {
                                                                    modelList.map((x, index) => {
                                                                        return (
                                                                            <option key={index} value={x.modelId}>{x.modelName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-2">
                                                        <div className="p-1 w-[100px]">
                                                            <label className="p-1 text-md">Make <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <select
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errMakeId}
                                                                value={makeId}
                                                                onChange={(e) => { setErrMakeId(''); setMakeId(e.target.value) }}>
                                                                <option value={-1}></option>
                                                                {
                                                                    makeList.map((x, index) => {
                                                                        return (
                                                                            <option key={index} value={x.makeId}>{x.makeName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-2">
                                                        <div className="p-1 w-[100px]">
                                                            <label className="p-1 text-md">Category <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <select
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errCategoryId}
                                                                value={categoryId}
                                                                onChange={(e) => { setErrCategoryId(''); setCategoryId(e.target.value) }}>
                                                                <option value={-1}></option>
                                                                {
                                                                    categoryList.map((x, index) => {
                                                                        return (
                                                                            <option key={index} value={x.catId}>{x.catName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 px-5">
                                                    <div className="flex flex-row py-2">
                                                        <div className="p-1 w-[110px]">
                                                            <label className="p-1 text-md">Year <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errYear}
                                                                value={year}
                                                                type='number' min='1800' minLength={4} maxLength={4}
                                                                onChange={(e) => { setErrYear(''); setYear(e.target.value ? e.target.value : null) }} />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-2">
                                                        <div className="p-1 w-[100px]">
                                                            <label className="p-1 text-md">Location <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <select
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errLocationId}
                                                                value={locationId}
                                                                onChange={(e) => { setErrLocationId(''); setLocationId(e.target.value) }}>
                                                                <option value={-1}></option>
                                                                {
                                                                    locationList.map((x, index) => {
                                                                        return (
                                                                            <option key={index} value={x.locId}>{x.locName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-row py-2">
                                                        <div className="p-1 w-[110px]">
                                                            <label className="p-1 text-md">Mileage <span className="text-red">*</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className={'py-1 pl-7 border-1 border-gray-primary w-full px-3 focus:outline-none focus:border-blue border ' + errMileage}
                                                                value={mileage}
                                                                type='number' min='0' minLength={4} maxLength={200}
                                                                onChange={(e) => { setErrMileage(''); setMileage(e.target.value ? e.target.value : null) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    < div className="flex items-center justify-end" >
                                        <button
                                            className="text-orange font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { clearState(); setShowModal(false) }}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="text-blue active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => addCar()}
                                        >
                                            Save Changes
                                        </button>
                                    </div >
                                </div >
                            </div >
                            <div className="opacity-25 bg-gray inset-0 fixed z-40" onClick={() => setShowModal(false)}></div>
                        </div >
                    </div>
                ) : null
            }
        </>
    );
}