import { useState, useEffect } from "react";
import axios from "axios";

export default function CarmakeNew() {
    const [showModal, setShowModal] = useState(false);
    const base_url = process.env.REACT_APP_BASEURL;

    //Car Details
    const [makeId, setMakeId] = useState();
    const [makeName, setMakeName] = useState();

    // Clear State
    const clearState = () => {
        setMakeId('')
        setMakeName('')
        setShowModal(false)
    }

    //Fields Error of Customer details
    const [errMakeId, setErrMakeId] = useState();
    const [errMakeName, setErrMakeName] = useState();


    //Add Car Model
    const addMake = () => {
        let valid = true;
        if (makeName === null) {
            setErrMakeName('Required')
            valid = false;
        }

        if (valid) {
            axios.post(base_url + "/addCarMake",
                {
                    makeName,
                })
                .then((response) => {
                    if (response.status == 200) {
                        console.log('Car Make added successfully')
                        clearState();
                    }
                    else
                        console.log(response);
                });
        }
    }

    return (
        <>
            <button
                className='p-2 bg-blue border border-b-[#fff] px-5 text-[#ffff] mx-1 hover:opacity-60 focus:border-blue ease-linear transition-all duration-150'
                type="button"
                onClick={() => setShowModal(true)}
            >
                New Make
            </button>
            {
                showModal ? (
                    <div className="fixed w-full">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 mx-auto max-w-3xl bg-[#fff] border-solid border-slate-200 rounded-t">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5  rounded-t">
                                        <h3 className="text-2xl font-semibold">
                                            New Make
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative flex-auto">
                                        <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-4 ml-2">
                                            <div className="flex flex-grow flex-col lg:flex-row place-items-center items-start px-3">
                                                <div className="flex-1 px-5">
                                                    <div className="flex flex-row py-3">
                                                        <div className="p-1">
                                                            <label className="p-1 text-md">Make Name <span className="text-red">* {errMakeName}</span></label>
                                                        </div>
                                                        <div className="relative pointer-events-auto flex-grow ml-3">
                                                            <input
                                                                className='py-1 pl-7 border-b-2 border-gray-primary w-full px-3 focus:outline-none focus:border-blue'
                                                                value={makeName}
                                                                onChange={(e) => { setErrMakeName(''); setMakeName(e.target.value) }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    < div className="flex items-center justify-end" >
                                        <button
                                            className="text-orange font-bold uppercase px-3 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => { setShowModal(false); }}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="text-blue active:bg-emerald-600 font-bold uppercase text-sm px-3 py-3 hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={() => addMake()}
                                        >
                                            Save Changes
                                        </button>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div>
                ) : null
            }
        </>
    );
}