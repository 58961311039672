import Header from "../Menu/Header";
import TopMenu from "../Menu/TopMenu";
import { useState, useEffect, useRef } from "react";
import { tdClass, trClass, tbodyClass } from '../Globals/Variables';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CarNew from "./CarNew";
import CarModelNew from "./CarModelNew";
import CarMakeNew from "./CarMakeNew";
import CarCategoryNew from "./CarCategoryNew";
import CarLocationNew from "./CarLocationNew";
import BackButton from "../Menu/BackButton";

export default function CarList() {
    const base_url = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate();
    const [emptyData, setEmptyData] = useState('No data to show')
    const [showAddMenu, setShowAddMenu] = useState('hidden');

    // Get Customers
    const [carList, setCarList] = useState([]);
    const getCars = () => {
        axios.post(base_url + '/getCars')
            .then((response) => {
                setCarList(response.data)
            })
    }

    useEffect(() => {
        getCars();
    }, [])

    return (
        <div className='z-1'>
            <div className="flex flex-col min-h-screen">
                <div className='w-100 z-1'>
                    <TopMenu />
                </div>
                <div className='flex flex-row bg-gray-light m-0 pr-6 flex-1 z-1'>
                    <div className='flex'>
                        <Header />
                    </div>
                    <div className=' flex-col flex-grow pl-3 m-0 my-2 z-1'>
                        <div className="flex py-3 z-1">
                            <BackButton />
                            <div className="flex-row flex-grow inline-block z-1">
                                <CarNew />
                            </div>
                            <div className="flex-row inline-block z-1">
                                <div className="" onClick={() => { showAddMenu === 'hidden' ? setShowAddMenu('block') : setShowAddMenu('hidden') }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 cursor-pointer text-blue">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                                    </svg>

                                </div>
                                <div className={'absolute right-[20px] flex flex-col ' + showAddMenu}>
                                    <CarModelNew />
                                    <CarMakeNew />
                                    <CarCategoryNew />
                                    <CarLocationNew />
                                </div>
                            </div>
                        </div>
                        <div className="w-100 bg-[#ffff] border border-gray-light z-1">
                            <table className="w-full z-1">
                                <thead>
                                    <tr className={trClass + ' font-bold border-b border-gray'}>
                                        <td className={tdClass}>#</td>
                                        <td className={tdClass}>Plate No.</td>
                                        <td className={tdClass}>Mark</td>
                                        <td className={tdClass}>Status</td>
                                        <td className={tdClass}>Location</td>
                                        <td className={tdClass}></td>
                                    </tr>
                                </thead>
                                <tbody className={tbodyClass}>
                                    {carList.length > 0 ?
                                        carList.map((x, index) => {
                                            return <tr key={index}>
                                                <td className={tdClass}>{index + 1}</td>
                                                <td className={tdClass + ' cursor-pointer hover:underline text-blue'} onClick={() => navigate('/CarDetails', { state: x })}>{x.plate}</td>
                                                <td className={tdClass}>{x.makeName}</td>
                                                <td className={tdClass}><span className={'p-2 text-[14px] rounded-[5px] ' + x.statusColor}>{x.statusName}</span></td>
                                                <td className={tdClass}>{x.locName}</td>
                                                <td>
                                                    <button className="text-[#ffff] bg-red border rounded-[5px] p-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                        </svg>
                                                    </button>
                                                    <button className="text-[#ffff] bg-blue border rounded-[5px] p-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-1">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        })
                                        : null}
                                </tbody>
                            </table>
                            {carList.length == 0
                                ? <div className="flex flex-grow w-100 justify-center italic mt-2 text-gray-dark">{emptyData}</div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}