import { useState, useEffect } from "react"
import { useGlobalState } from "../Globals/GlobalStates"
import axios from 'axios'


export default function General() {
    const [show, setShow] = useGlobalState('showGeneralTab')
    const base_url = process.env.REACT_APP_BASEURL
    const [currency, setCurrency] = useState('')
    const [errCurrency, setErrCurrency] = useState('')
    const [settingList, setSettingList] = useGlobalState('settingList')

    const saveGeneral = () => {
        if (currency == '')
            setErrCurrency('border-red')
        else {
            axios.post(base_url + '/saveCurrency', { currency })
                .then((response) => {
                    console.log(response)
                    if (response.status === 200) {
                        if (response.data.status === 'false')
                            console.log(response.data.result)
                        else
                            getSetting()
                    }
                    else
                        console.log(response)
                })
        }
    }

    const getSetting = () => {
        axios.post(base_url + '/getSetting')
            .then((response) => {
                if (response.status === 200 && response.data.status === 'true')
                    setSettingList(response.data.result)
            })
    }

    useEffect(() => {
        getSetting()
    }, [])

    useEffect(() => {
        if (settingList.length > 0)
            setCurrency(settingList.filter(x => x.setCode = 100)[0].setValue)
    }, [settingList])

    return (
        <div className={"flex flex-col m-1 p-1 py-3 border border-gray shadow relative " + show}>
            <div className="p-2 px-5 font-bold border-b border-b-gray mb-5">
                <h2>General Setting</h2>
                <div className="p-2 absolute top-1 right-3 cursor-pointer" onClick={() => setShow('hidden')}>x</div>
            </div>
            <div className="flex flex-row justify-start justify-items-start">
                <label className="p-2 px-3">Currency Symbol</label>
                <input className={"border border-gray rounded focus:outline-none px-2 focus:border-blue " + errCurrency} value={currency} onChange={(e) => { setErrCurrency(''); setCurrency(e.target.value) }} />
            </div>
            <div className="flex justify-end px-3 mt-5">
                <button className="p-2 px-4 bg-blue text-[#ffff] hover:opacity-60" onClick={() => saveGeneral()}>Save</button>
            </div>
        </div>
    )
}