import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FileIcon } from 'react-file-icon';
import Ico from '../../Assets/img/car.png';
import Header from '../Menu/Header';
import CarsCondition from '../../Assets/img/cars_condition.jpg';
import { tdClass, trClass, tbodyClass } from '../Globals/Variables';
import CarNoteNew from './CarNoteNew';
import { useGlobalState, setGlobalState } from '../Globals/GlobalStates';
import axios from 'axios';
import moment from 'moment';
import CarReservation from './CarReservation';
import Dropzone from 'react-dropzone';
import UploadAttachment from '../UploadAttachment';
import CarNew from './CarNew';
import BackButton from '../Menu/BackButton';

export default function CarDetails(props) {
    const { state } = useLocation();
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();
    const base_url = process.env.REACT_APP_BASEURL;
    const [refreshCarNote, setRefreshCarNote] = useGlobalState("updateCarNote");
    const [refreshReservation, setRefreshReservation] = useGlobalState("updateCarDetail");
    // Last Agrements
    const [reserveList, setReserveList] = useState();
    const [showReservationEmpty, setShowReservationEmpty] = useGlobalState('showReservationEmpty')
    const getReservation = () => {
        axios.post(base_url + '/getReservation', { carId: state.id })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.length > 0) {
                        setReserveList(response.data)
                        setShowReservationEmpty('hidden')
                    }
                    else
                        setShowReservationEmpty('')
                }
                else
                    console.log(response)
            })
    }

    // Notes
    const [carNoteList, setCarNoteList] = useState();
    const getCarNote = () => {
        axios.post(base_url + '/getCarNote', { carId: state.id })
            .then((response) => {
                setCarNoteList(response.data)
            })
    }

    const deleteNote = (id) => {
        var c = window.confirm('Are you sure you want to delete Note Id: ' + id);
        if (c) {
            axios.post(base_url + '/deleteCarNote', { noteId: id })
                .then((response) => {
                    if (response.status === 200)
                        getCarNote()
                    else
                        console.log(response)
                })
        }
    }

    useEffect(() => {
        getCarNote();
        setRefreshCarNote(false)
    }, [refreshCarNote])

    useEffect(() => {
        getReservation();
        setRefreshReservation(false)
    }, [refreshReservation])

    return (
        <div className='flex flex-row min-h-screen w-100 h-full bg-gray-light m-0 pr-6 z-1'>
            <div className='flex'>
                < Header />
            </div >

            <div className='flex-grow pl-3 m-0'>
                {/* Buttons */}
                <div className="flex py-3">
                    <BackButton />
                    <div className="flex-row inline-block">
                        <CarNew />
                        <CarReservation />
                        <button className='p-2 bg-blue border border-b-[#fff] px-5 text-[#ffff] mx-1 hover:opacity-60 focus:border-blue ease-linear transition-all duration-150'>Maintenance</button>
                    </div>
                </div>
                {/* Customer Info */}
                <div className=" flex flex-col flex lg:flex-row lg:h-[150px] bg-gray-light shadow drop-shadow-lg m-0 mt-4 lg:divide-x place-items-center">
                    <div className="flex-1 p-5">
                        <img src={Ico} className='object-contain h-20 w-20 border rounded-full' />
                    </div>
                    <div className="flex-1 flex-col h-100 p-5 px-10 inline-block w-full text-center">
                        <div className='uppercase font-bold text-md text-center'>
                            Plate No.
                        </div>
                        <div className='text-center'>
                            {(state) ? state.plate : null}
                        </div>
                    </div>
                    <div className="flex-1 flex-col h-100 p-5 px-10 inline-block  text-center">
                        <div className='uppercase font-bold text-md text-center'>
                            Status
                        </div>
                        <div className='text-center'>
                            {(state) ? <span className={'p-2 text-[14px] rounded-[5px] text-green '}>{state.statusName}</span> : null}
                        </div>
                    </div>
                    <div className="flex-1 flex-col h-100 p-5 px-10 inline-block  text-center">
                        <div className='uppercase font-bold text-md text-center'>
                            Model
                        </div>
                        <div className='text-center'>
                            {(state) ? state.modelName : null}
                        </div>
                    </div>
                    <div className="flex-1 flex-col h-100 p-5 px-10 inline-block  text-center">
                        <div className='uppercase font-bold text-md text-center'>
                            Make
                        </div>
                        <div className='text-center'>
                            {(state) ? state.makeName : null}
                        </div>
                    </div>
                    <div className="flex-1 flex-col h-100 p-5 px-10 inline-block  text-center">
                        <div className='uppercase font-bold text-md text-center'>
                            Location
                        </div>
                        <div className='text-center'>
                            {(state) ? state.locName : null}
                        </div>
                    </div>
                </div>

                {/* Last agrement & total revenue */}
                <div className='flex flex-col lg:flex-row mt-3 place-items-center align-middle'>
                    <div className='flex-1 bg-[#ffff] border border-gray-light shadow drop-shadow-lg h-[500px] px-4 py-2'>
                        <div className='text-orange font-bold text-[20px] flex p-2 place-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                            </svg>
                            <div>Reservation List</div>
                        </div>
                        <table className='w-full divide-y'>
                            <thead>
                                <tr className={trClass}>
                                    <th className={tdClass}>#</th>
                                    <th className={tdClass}>Date</th>
                                    <th className={tdClass}>Agreement #</th>
                                    <th className={tdClass}>Amount</th>
                                    <th className={tdClass}>Status</th>
                                </tr>
                            </thead>
                            <tbody className={tbodyClass}>
                                {reserveList
                                    ? reserveList.map((x, index) => {
                                        return <tr key={index}>
                                            <td className={tdClass}>{index + 1}</td>
                                            <td className={tdClass}>{moment(x.resCreatedDate).format('YYYY-MM-DD')}</td>
                                            <td className={tdClass}>{x.resId}</td>
                                            <td className={tdClass}>{x.resDuration * x.resPricePerDay}</td>
                                            <td className={tdClass}>{x.resStName}</td>
                                        </tr>
                                    })
                                    : null}
                            </tbody>
                        </table>
                        <div className={'text-center text-gray-text italic ' + showReservationEmpty}>No reservation yet</div>
                    </div>
                    <div className='flex-1 bg-[#ffff] border border-gray-light shadow drop-shadow-lg h-[500px] ml-1 px-4 py-2'>
                        <div className='text-orange font-bold text-[20px] flex p-2 place-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <div>Summary</div>
                        </div>
                        <table className='w-full divide-y divide-gray'>
                            <thead>
                                <tr className={trClass + ' text-[20px]'}>
                                    <th className={tdClass}>Total Revenue</th>
                                    <th className={tdClass + ' text-right'}>$ 9,460</th>
                                </tr>
                            </thead>
                            <tbody className={tbodyClass}>
                                <tr>
                                    <td className={tdClass}>Opened reservations</td>
                                    <td className={tdClass + ' text-right'}>10</td>
                                </tr>
                                <tr>
                                    <td className={tdClass}>Confirmed Reservations</td>
                                    <td className={tdClass + ' text-right'}>8</td>
                                </tr>
                                <tr>
                                    <td className={tdClass}>Cancelled Reservations</td>
                                    <td className={tdClass + ' text-right'}>2</td>
                                </tr>
                                <tr>
                                    <td className={tdClass}>Opened Agreements</td>
                                    <td className={tdClass + ' text-right'}>7</td>
                                </tr>
                                <tr>
                                    <td className={tdClass}>Closed Agreements</td>
                                    <td className={tdClass + ' text-right'}>5</td>
                                </tr>
                                <tr>
                                    <td className={tdClass}>Traffic Tickets</td>
                                    <td className={tdClass + ' text-right'}>0</td>
                                </tr>
                                <tr>
                                    <td className={tdClass}>Pending Payments</td>
                                    <td className={tdClass + ' text-right'}>2</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='flex flex-col w-100 mt-3 bg-[#ffff] shadow drop-shadow-lg px-4 py-2'>
                    <div className='text-orange font-bold text-[20px] flex p-2 place-items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                        </svg>
                        <div>Condition Report</div>
                    </div>
                    <div className='flex flex-grow'>
                        <div className='flex-grow px-5 py-10'>
                            <table className='w-full divide-y'>
                                <thead>
                                    <tr className={trClass}>
                                        <th className={tdClass}>#</th>
                                        <th className={tdClass}>Date</th>
                                        <th className={tdClass}>Condition</th>
                                    </tr>
                                </thead>
                                <tbody className={tbodyClass}>
                                    { }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <img src={CarsCondition} onMouseUp={(e) => { console.log(e) }} />
                        </div>
                    </div>
                </div>

                {/* Notes & attachement */}
                <div className='flex flex-col lg:flex-row mt-3 place-items-center align-middle'>
                    <div className='flex-1 bg-[#ffff] border border-gray-light shadow drop-shadow-lg h-[500px] px-4 py-2'>
                        <div className='flex flex-row'>
                            <div className='text-orange font-bold text-[20px] flex p-2 place-items-center w-100 flex-grow'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                </svg>
                                <div className='flex-grow'>Notes</div>
                            </div>
                            <div className=''>
                                <CarNoteNew data={state} />
                            </div>
                        </div>
                        <table className='w-full divide-y'>
                            <thead>
                                <tr className={trClass + ' text-[20px]'}>
                                    <th className={tdClass}>#</th>
                                    <th className={tdClass}>Date</th>
                                    <th className={tdClass}>Note</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className={tbodyClass}>
                                {
                                    carNoteList
                                        ? carNoteList.map((x, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className={tdClass}>{index + 1}</td>
                                                    <td className={tdClass}>{moment(x.noteDate).format("YYYY-MM-DD")}</td>
                                                    <td className={tdClass}>{x.noteText}</td>
                                                    <td>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer" onClick={() => deleteNote(x.noteId)}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : null
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='flex-1 bg-[#ffff] border border-gray-light shadow drop-shadow-lg h-[500px] ml-1 px-4 py-2'>
                        <div className='flex flex-row'>
                            <div className='text-orange font-bold text-[20px] flex p-2 place-items-center w-100 flex-grow'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                </svg>
                                <div className='flex-grow'>Notes</div>
                            </div>
                            <div className=''>
                                <UploadAttachment data={state} />
                            </div>
                        </div>
                        <table className='w-full divide-y divide-gray'>
                            <thead>
                                <tr className={trClass + ' text-[20px]'}>
                                    <th className={tdClass}>#</th>
                                    <th className={tdClass}>Name</th>
                                    <th className={tdClass}>File</th>
                                </tr>
                            </thead>
                            <tbody className={tbodyClass}>
                                <tr>
                                    <td className={tdClass}>1</td>
                                    <td className={tdClass}>License</td>
                                    <td className={tdClass + ' text-right'} >
                                        <div className='w-[32px]'>
                                            <FileIcon extension='PDF' foldColor='red' glyphColor='red' labelColor='red' type='acrobat' />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={tdClass}>2</td>
                                    <td className={tdClass}>Agreement Letter</td>
                                    <td className={tdClass + ' text-right'} >
                                        <div className='w-[32px]'>
                                            <FileIcon extension='Word' foldColor='blue' glyphColor='blue' labelColor='blue' type='document' />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={tdClass}>3</td>
                                    <td className={tdClass}>Insurance</td>
                                    <td className={tdClass + ' text-right'} >
                                        <div className='w-[32px]'>
                                            <FileIcon extension='Excel' foldColor='green' glyphColor='green' labelColor='green' type='spreadsheet' />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    )
}