import { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import axios from "axios";
import { UNSAFE_DataRouterStateContext, useLocation } from "react-router-dom";
import { useGlobalState, setGlobalState } from '../Globals/GlobalStates';
import moment from "moment";

export default function CustomerNew(props) {
    const [showModal, setShowModal] = useState(false);
    const base_url = process.env.REACT_APP_BASEURL;

    //Customer Details
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [stateName, setStateName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [membershipType, setMembershipType] = useState('');
    const [membershipId, setMembershipId] = useState('');
    const [licenseNo, setLicenseNo] = useState('');
    const [licenseExpiry, setLicenseExpiry] = useState('');

    // Clear State
    const clearState = () => {
        setFirstname('')
        setLastName('')
        setMiddleName('')
        setPhoneNumber('')
        setEmail('')
        setStreet('')
        setCity('')
        setStateName('')
        setZipCode('')
        setMembershipType('')
        setMembershipId('')
        setLicenseNo('')
        setLicenseExpiry('')
        setErr('')
    }

    //Fields Error of Customer details
    const [errFirstName, setErrFirstname] = useState();
    const [errLastName, setErrLastName] = useState();
    const [errMiddleName, setErrMiddleName] = useState();
    const [errPhoneNumber, setErrPhoneNumber] = useState();
    const [errEmail, setErrEmail] = useState();
    const [errStreet, setErrStreet] = useState();
    const [errCity, setErrCity] = useState();
    const [errStateName, setErrStateName] = useState();
    const [errZipCode, setErrZipCode] = useState();
    const [errMembershipType, setErrMembershipType] = useState();
    const [errMembershipId, setErrMembershipId] = useState();
    const [errLicenseNo, setErrLicenseNo] = useState();
    const [errLicenseExpiry, setErrLicenseExpiry] = useState();

    const [memberTypeList, setmemberTypeList] = useState([]);

    const getMemberTypes = () => {
        axios.post(base_url + "/getMemberType")
            .then((response) => {
                if (response.data.length > 0) {
                    setmemberTypeList(response.data)
                } else console.log('No data to show');
            });
    }

    useEffect(() => {
        getMemberTypes();
    }, [])

    const [err, setErr] = useState('')
    //Add Customer
    const addCustomer = async () => {
        let valid = true;
        const errMsg = 'All * filed are required.'
        if (!firstName) {
            console.log('First name is empty.')
            await setErrFirstname('Required')
            valid = false;
        }
        if (!lastName) {
            await setErrLastName('Required')
            valid = false
        }

        if (!phoneNumber) {
            await setErrPhoneNumber('Required')
            valid = false
        }
        if (!city) {
            await setErrCity('Required')
            valid = false
        }
        if (!stateName) {
            await setErrStateName('Required')
            valid = false
        }
        if (!membershipType) {
            await setErrMembershipType('Required')
            valid = false
        }
        if (!licenseNo) {
            await setErrLicenseNo('Required')
            valid = false
        }
        if (!licenseExpiry) {
            await setErrLicenseExpiry('Required')
            valid = false
        }

        if (valid) {
            await axios.post(base_url + "/addCustomer",
                {
                    firstName: firstName,
                    middleName: middleName,
                    lastName: lastName,
                    phoneNumber: phoneNumber,
                    email: email,
                    licenseNo: licenseNo,
                    licenseExpiry: licenseExpiry,
                    street: street,
                    city: city,
                    stateName: stateName,
                    zipCode: zipCode,
                    membershipType: membershipType,
                    membershipId: membershipId
                })
                .then((response) => {
                    if (response.status == 200) {
                        console.log('Customer added successfully')
                        clearState();
                    }
                    else
                        console.log(response);
                });
        }
        else {
            setErr(errMsg)
        }
    }

    return (
        <>
            <button
                className='p-2 bg-blue border border-b-[#fff] px-5 text-[#ffff] mx-1 hover:opacity-60 focus:border-blue ease-linear transition-all duration-150'
                type="button"
                onClick={() => setShowModal(true)}
            >
                New Customer
            </button>
            {
                showModal ? (
                    <div className="top-[50px] fixed w-full z-10 ">
                        <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                            <div className="relative flex-grow w-auto mt-20 mx-auto max-w-4xl bg-[#fff] border-solid border-slate-200 rounded-t shadow drop-shadow-xl z-50">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5  rounded-t">
                                        <h3 className="text-2xl font-semibold">
                                            New Customer
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-sm leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() => setShowModal(false)}
                                        >
                                            <span className="bg-transparent hover:opacity-50 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative flex-auto">
                                        <div className="flex-grow px-5 bg-[#fff]] m-0 mt-4 mb-4 ml-2">
                                            <div className="flex flex-wrap flex-grow flex-col lg:flex-row place-items-center items-start px-3">
                                                <div className="flex-1 px-5 mb-5 flex-grow">
                                                    <h3 className="font-bold">Customer Info</h3>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">First Name<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 w-auto max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={firstName}
                                                                onChange={(e) => { setErrFirstname(''); setFirstname(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">Middle Name<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={middleName}
                                                                onChange={(e) => { setErrMiddleName(''); setMiddleName(e.target.value) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">Last Name<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={lastName}
                                                                onChange={(e) => { setErrLastName(''); setLastName(e.target.value) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">Email <span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={email}
                                                                onChange={(e) => { setErrEmail(''); setEmail(e.target.value) }}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">Phone<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={phoneNumber}
                                                                onChange={(e) => { setErrPhoneNumber(''); setPhoneNumber(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 px-5 mb-5 ">
                                                    <h3 className="font-bold">Address</h3>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">City<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={city}
                                                                onChange={(e) => { setErrCity(''); setCity(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">Street<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={street}
                                                                onChange={(e) => { setErrStreet(''); setStreet(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">State<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={stateName}
                                                                onChange={(e) => { setErrStateName(''); setStateName(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">Zip Code<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={zipCode}
                                                                onChange={(e) => { setErrZipCode(''); setZipCode(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-wrap flex-grow flex-col lg:flex-row place-items-center items-start px-3">
                                                <div className="flex-1 px-5 mb-5 ">
                                                    <h3 className="font-bold">License Info</h3>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">License #<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={licenseNo}
                                                                onChange={(e) => { setErrLicenseNo(''); setLicenseNo(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">License Expiry Date<span className="text-red">*</span></label>
                                                            <input type={'date'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={licenseExpiry}
                                                                onChange={(e) => { setErrLicenseExpiry(''); setLicenseExpiry(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-1 px-5 mb-5 ">
                                                    <h3 className="font-bold">Other Info</h3>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">Member Type<span className="text-red">*</span></label>
                                                            <select type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={membershipType}
                                                                onChange={(e) => { setErrMembershipType(''); setmemberTypeList(e.target.value) }}
                                                            >
                                                                {
                                                                    memberTypeList
                                                                        ? memberTypeList.map((x, index) => {
                                                                            return <option key={index} value={x.typeId}>{x.typeName}</option>
                                                                        })
                                                                        : null
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="py-1">
                                                        <div className="p-1 w-auto inline-block flex justify-between">
                                                            <label className="p-1 text-md">Member Id<span className="text-red">*</span></label>
                                                            <input type={'text'}
                                                                className={'py-1 pl-7 border-1 border-gray-primary px-3 max-w-[230px] focus:outline-none focus:border-blue border ' + null}
                                                                value={membershipId}
                                                                onChange={(e) => { setErrMembershipId(''); setMembershipId(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                    {/*footer*/}
                                    <div className="flex w-full px-3">
                                        <div className="flex flex-grow pl-8 py-2 text-red justify-start">
                                            {err}
                                        </div>
                                        <div className="flex items-center justify-end">
                                            <button
                                                className="text-orange font-bold uppercase px-3 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => { clearState(); setShowModal(false); }}
                                            >
                                                Close
                                            </button>
                                            <button
                                                className="text-blue active:bg-emerald-600 font-bold uppercase text-sm px-3 py-3 hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() => { addCustomer() }}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fixed opacity-60 inset-0 z-40 bg-gray" onClick={() => setShowModal(false)}></div>
                        </div>
                    </div>
                ) : null
            }
        </>
    );
}